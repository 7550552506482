import { reactive } from 'vue'
import { useServiceCategoryCatalog } from '@/services/useServiceCategoryCatalog'
import { useFetchData } from '@/composables/useFetchData'

interface CategoryCatalog {
  _id?: string
  title: string
  parents?: string
  status: boolean
}
export default function useCategoryCatalogRepositories() {
  const resource = `/catalog-categories`
  // const resource = 'http://localhost:3003/api/catalog-categories'

  const state = reactive({
    categories: [] as CategoryCatalog[],
    category: {} as CategoryCatalog,
  })

  const fetchAllCategories = async () => {
    const { documents, fetchAll } = useFetchData(resource)
    await fetchAll()
    state.categories = (documents.value as CategoryCatalog[]) ?? []
    return documents.value
  }

  const addCategoryCatalog = async (category: CategoryCatalog) => {
    const { add } = useServiceCategoryCatalog(resource, category)
    try {
      const { status, data } = await add(category)
      return { status, data }
    } catch (error) {
      console.log(error)
      return {}
    }
  }

  const updateCategoryCatalog = async (category: CategoryCatalog) => {
    const { patch } = useServiceCategoryCatalog(
      `${resource}/${category._id}`,
      category
    )
    try {
      const { status, data } = await patch(category)
      return { status, data }
    } catch (error) {
      console.log(error)
      return {}
    }
  }

  const deleteById = async (category: CategoryCatalog) => {
    const { deleteOne } = useServiceCategoryCatalog(resource, category)
    try {
      const { status, data } = await deleteOne(category._id)
      return { status, data }
    } catch (error) {
      console.log(error)
      return {}
    }
  }

  return {
    addCategoryCatalog,
    updateCategoryCatalog,
    deleteById,
    fetchAllCategories,
  }
}
