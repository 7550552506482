
import { defineComponent, ref } from 'vue'

export default defineComponent({
  components: {},
  props: {
    catalogType: { type: String, required: true },
    tagSize: { type: String },
  },
  setup(props) {
    const tagSizeValue = ref(props.tagSize ?? 'small')
    const getTagType = (catalogType: string) => {
      if (catalogType === 'pod') {
        return 'info'
      }
      if (catalogType === 'drop' || catalogType === 'dropship') {
        return 'primary'
      }
      if (catalogType === 'fake') {
        return 'warning'
      }
      return 'danger'
    }
    return { getTagType, tagSizeValue }
  },
})
