import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "flex items-center justify-start"
}
const _hoisted_3 = {
  key: 2,
  class: "base-cost"
}
const _hoisted_4 = { key: 3 }
const _hoisted_5 = { key: 4 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatusTag = _resolveComponent("StatusTag")!
  const _component_EditRowButton = _resolveComponent("EditRowButton")!
  const _component_DeleteRowButton = _resolveComponent("DeleteRowButton")!
  const _component_catalog_type_tag = _resolveComponent("catalog-type-tag")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createVNode(_component_el_table, {
      data: _ctx.documents,
      lazy: _ctx.isLoading,
      stripe: "",
      style: {"width":"100%"},
      onRowClick: _ctx.click
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
          return (_openBlock(), _createBlock(_component_el_table_column, {
            key: column.dataIndex,
            prop: column.dataIndex,
            label: column.title,
            "tooltip-effect": column.dataIndex,
            "min-width": column.min_width
          }, {
            default: _withCtx((scope) => [
              (column.dataIndex === 'status')
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_StatusTag, {
                      status: scope.row[column.dataIndex] === true,
                      trueText: "Active",
                      falseText: "Inactive"
                    }, null, 8, ["status"])
                  ]))
                : (column.title === 'Action')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_EditRowButton, {
                        onClick: ($event: any) => (_ctx.editCategory(scope.row))
                      }, null, 8, ["onClick"]),
                      _createVNode(_component_DeleteRowButton, {
                        onClick: ($event: any) => (_ctx.deleteCategory(scope.row))
                      }, null, 8, ["onClick"])
                    ]))
                  : (column.dataIndex === 'title')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(scope.row[column.dataIndex]), 1))
                    : (column.dataIndex === 'type')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createVNode(_component_catalog_type_tag, {
                            catalogType: scope.row.type
                          }, null, 8, ["catalogType"])
                        ]))
                      : (column.dataIndex === 'createdDate')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            (scope.row?.createdDate)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.formatDateTime(scope.row.createdDate)), 1))
                              : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.formatDateTime(scope.row.createdAt)), 1))
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(scope.row[column.dataIndex]), 1))
            ]),
            _: 2
          }, 1032, ["prop", "label", "tooltip-effect", "min-width"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["data", "lazy", "onRowClick"]), [
      [_directive_loading, _ctx.isLoading]
    ])
  ]))
}