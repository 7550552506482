
import { formatDateTime } from '@/utils/dateTime'
import { defineComponent, computed } from 'vue'
import CatalogTypeTag from '@/components/orders/display/CatalogTypeTag.vue'
import StatusTag from '@/components/common/tags/StatusTag.vue'
import EditRowButton from '@/components/common/buttons/EditRowButton.vue'
import DeleteRowButton from '@/components/common/buttons/DeleteRowButton.vue'

export default defineComponent({
  components: { CatalogTypeTag, StatusTag, EditRowButton, DeleteRowButton },
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    // const router = useRouter()
    const columns = computed(() => {
      let defaultColumns = [
        {
          title: 'Created',
          dataIndex: 'createdDate',
          min_width: '15',
        },
        {
          title: 'Name',
          dataIndex: 'title',
          min_width: '35',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          min_width: '10',
        },
        {
          title: 'Type',
          dataIndex: 'type',
          min_width: '10',
        },
      ]
      defaultColumns.push({
        title: 'Ref',
        dataIndex: 'ref',
        min_width: '10',
      })
      defaultColumns.push({
        title: 'Action',
        dataIndex: 'action',
        min_width: '10',
      })
      return defaultColumns
    })
    const click = (item: any) => {
      console.log('documentsss', props.documents)
      console.log('click', item)
    }
    const editCategory = (item: any) => {
      emit('update', item)
    }
    const deleteCategory = (item: any) => {
      emit('del', item)
    }
    return {
      columns,
      click,
      formatDateTime,
      editCategory,
      deleteCategory,
    }
  },
})
