import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tag = _resolveComponent("el-tag")!

  return (_openBlock(), _createBlock(_component_el_tag, {
    size: _ctx.tagSizeValue,
    type: _ctx.getTagType(_ctx.catalogType),
    effect: "dark",
    class: "rounded custom-capitalize"
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.catalogType), 1)
    ]),
    _: 1
  }, 8, ["size", "type"]))
}