
import { defineComponent } from 'vue'

export default defineComponent({
  components: {},
  props: {
    title: {
      type: String,
    },
  },
  setup() {
    return {}
  },
})
