
import Pagination from '@/components/common/Pagination.vue'
import { computed, reactive, watch, ref, defineComponent } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useFetchData } from '@/composables'
import List from './components/List.vue'
import FilterTable from '@/components/common/FilterTable.vue'
import { useNotification } from '@/composables'
import useCategoryCatalogReponsitories from '@/repositories/useCategoryCatalogReponsitories'
import PageTitle from '@/components/common/PageTitle.vue'

export default defineComponent({
  components: {
    sectionLayoutContent,
    List,
    FilterTable,
    Pagination,
    PageTitle,
  },
  setup() {
    const breadcrumbs = ['Category catalog', '']
    const title = 'Title'
    const titleCreate = 'Create'
    const iconCreate = 'fas fa-plus'

    const content = computed(() => {
      return { breadcrumbs, title, titleCreate, iconCreate }
    })
    // const resource = `http://localhost:3003/api/catalog-categories`
    const resource = `/catalog-categories`

    const params = reactive({ search: null, page: 1, limit: 10 })

    const { documents, fetchData, isLoading, total } = useFetchData(
      resource,
      params
    )
    fetchData()
    const dialog = ref<boolean>(false)
    const { success, error } = useNotification()
    watch(params, fetchData)

    const form = reactive({
      _id: null as any,
      title: null as any,
      parent: null as any,
      status: true,
      type: 'pod',
      ref: 0,
    })

    const create = () => {
      type.value = 'create'
      form._id = null
      form.title = null
      form.parent = null
      form.status = true
      form.type = ''
      form.ref = 0
      // enable dialog
      dialog.value = true
      console.log('document', documents)
    }

    const rules = {
      title: [
        {
          required: true,
          message: 'Please input name',
          trigger: 'blur',
        },
      ],
    }

    const categoryCatalog = ref<any>(null)

    const addCategory = async () => {
      categoryCatalog.value?.validate(async (valid: any) => {
        if (valid) {
          const { addCategoryCatalog, updateCategoryCatalog } =
            useCategoryCatalogReponsitories()
          if (type.value === 'update') {
            let { status } = await updateCategoryCatalog(form)
            if (status === 200) {
              dialog.value = false
              Object.assign(form, { title: '', parent: '', status: false })
              fetchData()
            }
          } else {
            let { status } = await addCategoryCatalog(form)
            if (status === 201) {
              dialog.value = false
              Object.assign(form, { title: '', parent: '', status: false })
              fetchData()
            }
          }
        }
      })
    }

    const cancel = () => {
      dialog.value = false
      Object.assign(form, { title: '', parent: '', status: false })
    }

    const type = ref<string>('')
    const update = (item: any) => {
      type.value = 'update'
      Object.assign(form, item)
      dialog.value = true
    }

    const dialogDel = ref<boolean>(false)
    const del = (item: any) => {
      Object.assign(form, item)
      dialogDel.value = true
    }

    const cancelDelete = async () => {
      dialogDel.value = false
    }

    const onFilterCallback = (data: any) => {
      params.search = data?.search
    }

    const confirmDelete = async () => {
      const { deleteById } = useCategoryCatalogReponsitories()
      let { status } = await deleteById(form)
      if (status === 200) {
        success('Success')
        dialogDel.value = false
        Object.assign(form, { title: '', parent: '', status: false })
        fetchData()
      } else {
        error('This category cannot be removed')
      }
    }
    return {
      content,
      documents,
      isLoading,
      activeKey: ref('1'),
      create,
      params,
      total,
      dialog,
      form,
      rules,
      categoryCatalog,
      addCategory,
      update,
      del,
      cancel,
      type,
      cancelDelete,
      dialogDel,
      confirmDelete,
      onFilterCallback,
    }
  },
})
